<script lang="ts">
  import { nrkClose, nrkUpload } from "@nrk/core-icons";
  import { orgClose, orgMagnifier } from "@nrk/origo";
  import UploadButton from "components/Upload/UploadButton.svelte";
  import { getBildebankenHost, getCloseCallback, showCloseButton } from "config";
  import { debounce, upperFirst } from "lodash-es";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import { pageState, resetPage, searchByQuery, searchText } from "state/page";
  import { getMimirSearchQuery } from "state/queries/mimir";
  import { getEnvironmentFromHostname } from "utils/fns";
  import SearchPills from "../SearchPage/SearchPills.svelte";
  import { imageSize, setImageSize } from "../SearchPage/imageSize";
  import Logo from "./Logo.svelte";
  import { useFeatureToggle } from "services/configCatService";
  import {
    cancelUpload,
    cleanupAndResetUploadState,
    setUploadsIdle,
    uploads,
  } from "../../state/upload";
  import HelpMenu from "../HelpMenu.svelte";

  const query = getMimirSearchQuery();

  $: editMode = $pageState.type === "EDIT" && $pageState.editOnlyMode;
  $: uploadMode = $pageState.type === "UPLOAD";
  let searchFieldFocus = false;
  export let authenticated = true;

  const handleSearch = debounce((event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      searchByQuery(event.target.value);
    }
  }, 300);

  const handleSearchOnEnter = debounce((event: KeyboardEvent) => {
    if (event.key === "Enter") {
      if (event.target instanceof HTMLInputElement) {
        searchByQuery(event.target.value);
      }
    }
  }, 300);

  $: scale = $imageSize;

  const environment = getEnvironmentFromHostname(getBildebankenHost().hostname);
  const environmentColors = {
    dev: "--nrk-color-warm-mint-600",
    stage: "--nrk-color-warm-orange-600",
    beta: "--nrk-color-core-blue-600",
  };

  $: setImageSize(scale);

  const cancelUploads = async (event: MouseEvent, close: boolean) => {
    event.preventDefault();
    if ($uploads.state !== "ALL_FAILED" && confirm("Hvis du avbryter vil opplastingene slettes")) {
      await cancelUpload();
      resetPage();
      const hasCloseCallback = getCloseCallback();
      if (hasCloseCallback && close) {
        hasCloseCallback();
      }
    }
  };

  const cancelCallback = async (event: MouseEvent) => {
    if (uploadMode) {
      await cancelUploads(event, true);
    } else {
      const close = getCloseCallback();
      if (close) {
        close();
      }
    }
  };

  let mounted = false;
  onMount(() => (mounted = true));

  let inputElement: HTMLInputElement;
  function setFocusInViewport(e) {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        searchFieldFocus = true;
        inputElement?.focus();
      }
    });
    observer.observe(e);
  }

  const onClickLogo = async (event: MouseEvent) => {
    if (uploadMode) {
      await cancelUploads(event, false);
    } else {
      if (!editMode) {
        resetPage();
      }
    }
  };
</script>

<div class="top-bar">
  {#if environment !== "prod"}
    <div class="banner" style={`background: var(${environmentColors[environment]})`}>
      {upperFirst(environment)}
    </div>
  {/if}
  <button class="reset header logo" on:click={onClickLogo}>
    <Logo />
  </button>
  {#if authenticated && mounted && !editMode && !uploadMode}
    <form
      in:fade={{ duration: 500 }}
      class="search"
      class:focus={searchFieldFocus}
      role="search"
      on:submit|preventDefault={() => $query.refetch()}
      aria-label="Fritekstsøk"
    >
      {#await useFeatureToggle("searchOnEveryKeyEvent") then featureEnabled}
        <label>
          <span class="icon-left">{@html orgMagnifier}</span>
          <span class="org-sr">Søk etter bilde</span>
          <input
            bind:this={inputElement}
            use:setFocusInViewport
            tabindex={1}
            type="text"
            name="search"
            placeholder="Finn bilde…"
            on:keyup|preventDefault={featureEnabled ? handleSearch : handleSearchOnEnter}
            value={$searchText}
            on:focus={() => (searchFieldFocus = true)}
            on:blur={() => (searchFieldFocus = false)}
            data-testid="search-bar"
            spellcheck="false"
            autocomplete="off"
          />
          <SearchPills />
          <div class="clear" class:hidden={$searchText.length === 0}>
            <button type="reset" class="reset" on:click|preventDefault={() => searchByQuery("")}>
              {@html orgClose}
            </button>
          </div>
        </label>
      {/await}
    </form>
  {/if}

  <div class="right">
    {#if authenticated && !editMode && !uploadMode}
      <UploadButton>
        <div class="org-button org-button--secondary" style="font-size: .7rem;">
          {@html nrkUpload}
        </div>
      </UploadButton>
    {/if}
    <div style="margin-right: -0.25rem">
      <HelpMenu />
    </div>
    {#if $showCloseButton}
      <button class="org-button" on:click={cancelCallback} title="Avbryt">
        {@html nrkClose}
      </button>
    {/if}
  </div>
</div>

<style>
  .top-bar {
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--color-surface-tertiary);
    height: 55px;
    gap: 20px;
  }

  .org-button {
    color: var(--color-text-primary);
  }

  .banner {
    position: absolute;
    font-size: 0.75rem;
    rotate: -50deg;
    width: 80px;
    height: 18px;
    text-align: center;
    top: 5px;
    left: -27px;
    transition: 0.35s;
  }

  .clear {
    background: gray;
    border-radius: 100%;
    font-size: 0.5rem;
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: flex;
  }

  .clear button {
    color: var(--color-text-invert);
    padding: 0;
    flex: 1;
    cursor: pointer;
  }

  .search {
    position: relative;
    display: flex;
    flex-flow: row;
    width: 50%;
    min-width: 300px;
    height: 38px;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border: solid 1px var(--org-color-shade-2);
    border-radius: 3px;
    max-width: 650px;
    transition: flex 0.25s ease-in-out;
    background: var(--color-surface-secondary);
  }

  .search .icon-left {
    color: var(--color-text-inactive);
  }

  .search.focus {
    box-shadow: var(--org-shadow-focus);
    flex-grow: 1;
  }

  .search > label {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .search > label > :global(svg) {
    font-size: 12px;
  }

  .header {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .search input {
    margin-left: 10px;
    background: transparent;
    color: var(--color-text-primary);
    font-size: 14px;

    letter-spacing: -0.2px;
    outline: none;
    border: 0;

    flex: 1;
    width: 100%;
    padding: 10px 0;
  }

  .search input::placeholder {
    color: var(--color-text-inactive);
  }

  .right {
    flex: 0;
    display: flex;
    align-items: center;
  }

  .reset {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0;
    background: none;
    padding: 0;
    border: none;
  }

  button {
    color: var(--color-text-primary);
  }
</style>
