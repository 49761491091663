<script lang="ts">
  import type { Image } from "bildebanken-model";
  import type { EditImageActor } from "./editImageMachine";
  import { validDateTime } from "../../utils/fns";
  import { updateField } from "./editImageMachine";

  export let service: EditImageActor;
  export let image: Image;

  let value = image.metadata.mediaCreatedOn
    ? new Date(image.metadata.mediaCreatedOn).toISOString().substring(0, 19)
    : "";

  let invalid = false;
  $: futureMediaCreatedOnDate =
    image?.metadata?.mediaCreatedOn && new Date(image.metadata.mediaCreatedOn) > new Date();
  function updateDate(this: HTMLInputElement) {
    if (validDateTime(this.value)) {
      updateField(service, "mediaCreatedOn", new Date(this.value).toISOString());
      invalid = false;
    } else {
      updateField(service, "mediaCreatedOn", "invalid");
      invalid = true;
    }
  }

  // TODO: Add timezone support using Intl.supportedValuesOf('timeZone')
</script>

<label>
  Dato fotografert
  <input type="datetime-local" class="org-input" bind:value on:change={updateDate} />
</label>
{#if invalid}<div class="org-warning">Ugyldig dato</div>{/if}
{#if futureMediaCreatedOnDate}<span class="org-warning">Fremtidig fotografert dato</span> {/if}
